<template>
<section id="wrapper">
  <div class="login-register">
    <div class="login-box card mt-4" style="border-radius:24px">
      <div class="card-body">
        <template 
          v-if="type=='login'"
        >
          <ValidationObserver 
            v-slot="{ handleSubmit }" 
            ref="VForm">
            <form 
              @submit.prevent="handleSubmit(doLogin('/do/login'))"
              id="loginform"
              class="form-horizontal form-material"
            >
              <figure class="text-center mb-4">
                <img :src="baseUrl+'/img/logo/logo-jrf-new.png'" class="w-50"/>
              </figure>
              <h3 class="text-center f-600 m-b-5"> 
                Log In
              </h3>
              <p class="text-center">
                Please sign-in to your account to continue
              </p>
              <div class="infoHTML"></div>
              <div class="form-group ">
                <input 
                  v-model="input.username"
                  class="form-control" 
                  type="text" 
                  placeholder="Username"
                />
                <VValidate 
                  name="Username" 
                  v-model="input.username" 
                  rules="required" 
                />
              </div>
              <div class="form-group">
                <input 
                  v-model="input.password"
                  class="form-control" 
                  type="password" 
                  placeholder="Password"
                />
                <VValidate 
                  name="Password" 
                  v-model="input.password" 
                  rules="required" 
                />
              </div>
              <!-- <div class="form-group mb-3 row">
                <div class="col-md-12">
                  <div class="d-flex no-block align-items-center">
                    <div class="custom-control custom-checkbox">
                      <input 
                        v-model="input.rememberMe"
                        type="checkbox" 
                        class="custom-control-input" 
                        id="customCheck1"
                      />
                      <label 
                        class="custom-control-label cursor-pointer" 
                        for="customCheck1">
                          Remember me
                      </label>
                    </div>
                    <div class="ml-auto">
                      <a href="javascript:void(0)" id="to-recover" class="text-muted"><i class="fas fa-lock m-r-5"></i> Forgot Password?</a>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="form-group text-center mt-4">
                <div class="col-xs-12 p-b-20">
                  <button 
                    type="submit" 
                    class="btn btn-block btn-lg btn-purple btn-rounded">
                      Log In
                  </button>
                </div>
              </div>

              <div class="form-group m-b-0">
                <div class="col-sm-12 text-center">
                  Copyright © 2024 Jakarta Running Festival. All Rights Reserved.
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import GlobalVue from '@libs/Global'
let $ = global.jQuery
export default {
  extends:GlobalVue,
  data(){
    return {
      type:'login'
    }
  },
  methods:{
    doLogin(action){
      this.doSubmit(action, this.input, (_, resp)=>{
        this.loading = false
        if (resp.status != 'success') return;

        $(".info").attr("class","").text("")
        if (!resp.data.token) return this.type = "2fa"

        const redirectUrl = String(this.baseUrl).replace('/dist', '')

        this.$store.commit('updateUserToken',resp.data.token)
        this.$store.commit('updateUser', resp.data.user)
        if(this.$route.query.continue) {
          return window.location=redirectUrl+''+this.$route.query.continue
        }
        window.location=redirectUrl+'/dashboard'
      })
    }
  },
}
</script>

<style scoped>
@import "../assets/css/pages/login-register-lock.css";

.login-register {
  background-image: url('../assets/img/static-bg/Top-banner-Desktop.jpg');
  background-size: cover;
  /* background: #edf1f5 !important; */
}
</style>